#gifs-page * {
    overflow-x: hidden !important;
}
#partnership-section {
    div.rightSide {
        overflow: hidden;
        @keyframes device {
            0% {
                transform: rotate(180deg) translate(6000px, 300px);
                opacity: 0
            }

            50% {
                opacity: 1
            }

            100% {
                transform: rotate(10deg) translate(0)
            }
        }

        .device {
            position: relative;
            animation: device 1.5s cubic-bezier(0.645, 0.045, 0.355, 1.000) forwards;
            overflow: hidden;
                /* Hides content spilling outside */
                padding-bottom: 20px;
                padding-top: 20px;
                /* Add some padding to prevent the cut-off */
            svg {
                position: absolute;
                z-index: 1000;
                opacity: 0;

                &:first-child {
                    top: 170px;
                    right: -70px;
                    animation: create 31s ease-in-out forwards;

                    @keyframes create {
                        0% {
                            opacity: 0
                        }

                        5% {
                            opacity: 0
                        }

                        6% {
                            opacity: 1
                        }

                        25% {
                            opacity: 1
                        }

                        26% {
                            opacity: 0
                        }
                    }
                }

                &:nth-child(2) {
                    top: 400px;
                    left: -70px;
                    animation: amount 31s ease-in-out forwards;

                    @keyframes amount {
                        0% {
                            opacity: 0
                        }

                        35% {
                            opacity: 0
                        }

                        36% {
                            opacity: 1
                        }

                        53% {
                            opacity: 1
                        }

                        54% {
                            opacity: 0
                        }
                    }
                }

                &:nth-child(3) {
                    top: 550px;
                    right: -120px;
                    animation: merchant 31s ease-in-out forwards;

                    @keyframes merchant {
                        0% {
                            opacity: 0
                        }

                        55% {
                            opacity: 0
                        }

                        56% {
                            opacity: 1
                        }

                        72% {
                            opacity: 1
                        }

                        73% {
                            opacity: 0
                        }
                    }
                }

                &:nth-child(4) {
                    top: 400px;
                    left: -100px;
                    animation: spend 35s ease-in-out forwards;

                    @keyframes spend {
                        0% {
                            opacity: 0
                        }

                        89% {
                            opacity: 0
                        }

                        90% {
                            opacity: 1
                        }

                        99% {
                            opacity: 1
                        }

                        100% {
                            opacity: 1
                        }
                    }
                }
            }

            video {
                position: absolute;
                z-index: 100;

                right: 50%;
                transform: translateX(50%);
                top: 40px;

                width: 100%;
                max-width: 360px;
                height: 765px;
                object-fit: cover;
                border-radius: 32px;

                @media (max-width: 480px) {
                    width: 217px !important;
                    height: 471px !important;
                    top: 36px !important;
                }

                @media (max-width: 1149px) {
                    width: 259px;
                    height: 540px;
                    top: 34px;
                    border-radius: 24px;
                }
            }
        }

        @media (max-width: 1550px) and (min-width: 1150px) {
            transform: scale(0.8);
        }

        @media (max-width: 1149px) {
            padding-top: 0px;
            margin-bottom: 60px;

            svg {
                filter:
                    drop-shadow(0px 0px 8px #ffffff) drop-shadow(0px 0px 8px #ffffff);

                &:first-child {
                    top: unset !important;
                    bottom: -50px !important;
                    left: 10% !important;

                    path {
                        display: none;
                    }

                    g#Create-a-goal path {
                        display: block;
                    }
                }

                &:nth-child(2) {
                    top: unset !important;
                    bottom: -60px !important;
                    left: 25% !important;

                    g {
                        path {
                            &:first-child {
                                display: none;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    top: unset !important;
                    bottom: -80px !important;
                    right: -10% !important;

                    g {
                        path {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }

                &:nth-child(4) {
                    top: unset !important;
                    bottom: -70px !important;
                    left: 30% !important;

                    g {
                        path {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-height: 830px;
            // box-shadow: 0px 8px 16px rgba(13, 1, 25, 0.2);
                /* Safer shadow effect */
            transform: translateZ(0);
            border-radius: 32px;
            @media (max-width: 480px) {
                height: 500px !important;
            }

            @media (max-width: 1149px) {
                height: 600px;
            }
        }
    }
}