@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow-x: hidden;
}

html,
body {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
  width: 100%;
}

@font-face {
  font-family: 'Telegraf-UltraLight';
  src: url('./assets/fonts/Telegraf-UltraLight.otf');
}
@font-face {
  font-family: 'Telegraf-Regular';
  src: url('./assets/fonts/Telegraf-Regular.otf');
}
@font-face {
  font-family: 'Telegraf-SemiBold';
  src: url('./assets/fonts/Telegraf-SemiBold.woff2');
}
@font-face {
  font-family: 'Telegraf-Ultrabold';
  src: url('./assets/fonts/Telegraf-UltraBold.woff2');
  //
  font-weight: 700;
}
@font-face {
  font-family: 'Saathi-UltraBold';
  src: url('./assets/fonts/Saathi-UltraBold.otf');
}

html, body {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Saathi-UltraBold';
  font-weight: 800;
}

h1 {
  font-size: 64px;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 64px;
}

p {
  font-family: 'Telegraf-Regular';
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
}

p.headerCaption {
  opacity: 0.5;
  text-align: center;
  font-size: 24px;
  text-align: center;
}

button {
  font-family: 'Saathi-UltraBold';
}

div.radialPinkBlueGradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -2;
  //#FBA4DA -> #99C7FB
  background: radial-gradient(rgba(251, 164, 218, 1) 0%, rgba(153, 199, 251, 1) 100%);
}
div.whiteningLinearGradient {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.5) 100%);
}

section.topGradientContainer {
  position: relative;
  div.titleArea {
    padding-top: 301px;
    * {
      text-align: center !important;
    }
    h5 {
      margin-top: 0px;
      margin-bottom: 8px;
      color: rgb(200, 153, 216);
    }
    h1 {
      margin-top: 0px;
      margin-bottom: 24px;
    }
    p {
      margin-top: 0px;
      font-size: 24px;
      line-height: 34px;
      opacity: 0.72;
    }
  }
}

select:required:invalid { color: gray; }