#LegalDocument {
  ul,ol {
    list-style-type: disc;
    ol {
      list-style-type: lower-alpha;
      margin-left: 40px;
    }
  }

  section.headerSection {
    overflow: hidden;
    position: relative;
    margin-bottom: 85px;
    
    div.radialPinkBlueGradient {
      border: 2px solid blue;
      background: radial-gradient(
        rgba(153, 199, 251, 1) 0%,
        rgba(251, 164, 218, 1) 100%
      );
    }
    
    .title {
      padding: 160px 0 80px;
      
      
      h1 {
        text-align: center;
        margin: 0;
      }
      
      h5 {
        margin: 16px 0 0 0;
        
        font-family: 'Saathi-UltraBold';
        font-size: 16px;
        line-height: 15px;
        font-weight: 800;
        font-style: normal;
        letter-spacing: 1px;
        color: rgb(200, 153, 216);
        text-align: center;
      }
    }

    td {
      font-family: 'Telegraf-Regular';
      font-size: 11px;
    }
  }

  section.documentContents {
    width: 100%;
    max-width: 800px;
    margin: 0px auto 160px auto;
    box-sizing: border-box;
    
    @media (max-width: 800px) {
      padding: 0 24px;
    }
    
    table {
      border-collapse: collapse;
      border-spacing: 0;
      
      border-color: #d7d7d7;
      
      img {
        max-width: 40%;
        margin: 16px 8px;
      }
      
      @media (max-width: 800px) {
        width: 100% !important;
      }
    }
    
    p {
      font-size: 14px;
    }
    
    li {
      font-size: 14px;
    }
    
    a {
      color: #f81ca3;
    }
    
    td {
      font-family: 'Telegraf-Regular';
      font-size: 12px;
      line-height: 1.2em;
      
      padding: 6px 8px;
    }
  }
}