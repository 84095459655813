.why {
	ul {
		padding: 0;
		
		.item {
			display: none;
			
			&.active {
				display: grid;
			}
		}
	}
	
	.graphic {
		grid-column-start: 2;
		grid-column-end: 7;
		
		background: #ffffff;
		border-radius: 16px;
		
		min-height: 600px ;

		position: relative;
		
		@media (max-width: 800px) {
			grid-column: span 12;
		}

		.imageOnGraphic {
          width: 100%;
		  height: 100%;
		  max-width: 80%;
		  max-height: 80%;
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translateX(-50%) translateY(-50%);
		}
	}


	
	.indicators {
		display: flex;
		flex-direction: column;
		list-style: none;
		margin: auto 0;
		
		@media (max-width: 800px) {
			flex-direction: row;
		}
		
		li {
			border: 1px solid #000;
			width: 10px;
			height: 10px;
			margin-top: 12px;
			border-radius: 6px;
			
			@media (max-width: 800px) {
				margin-right: 12px;
			}
			
			&.active {
				background: #000;
			}
		}
	}
	
	.content {
		grid-column-start: 8;
		grid-column-end: 12;
		
		display: flex;
		flex-direction: column;
		
		padding: 64px 0 120px 0;
		
		@media (max-width: 800px) {
			grid-column: span 12;
			padding: 16px 0 40px 0;
			min-height: 400px;
		}
		
		.subheading {
			margin-bottom: 16px;
			
			font-family: Saathi-UltraBold;
			font-size: 24px;
			font-weight: 900;
			text-transform: uppercase;
			
			background-image: linear-gradient(60deg, #F81CA3, #d81eaf);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		
		h3 {
			font-size: 40px;
			line-height: 1.1em;
			margin: 0;
		}
		
		p {			
			display: flex;
			font-size: 20px;
			line-height: 1.4em;
			color: #000000;
			height: 100%;
			align-items: center;
			
			@media (max-width: 800px) {
				margin-top: 40px;
			}
		}
	}
}