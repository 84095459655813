.manifesto-scroll-container {
  width: 400vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  @media screen and (max-width: 500px) {
    width: 700vw;
    height: 70vh;
  }
}

.manifesto-svg {
  height: 90vh; // Adjust as needed
  width: 100%; // Ensures it scales correctly
  position: absolute;
  left: 0;
  top: 10%;
  transform: translateY(-10%);
  will-change: transform; // Optimizes animation performance

  @media screen and (max-width: 500px) {
    height: 50vh;
  }
}